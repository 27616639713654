<script setup>
import { Head, Link, useForm, usePage } from '@inertiajs/vue3';

import FullWidthButton from '@/Components/Buttons/FullWidthButton.vue';
import Checkbox from '@/Components/Form/Checkbox.vue';
import InputError from '@/Components/Form/InputError.vue';
import InputLabel from '@/Components/Form/InputLabel.vue';
import PasswordInput from '@/Components/Form/PasswordInput.vue';
import TextInput from '@/Components/Form/TextInput.vue';
import GoogleIcon from '@/Components/Icons/GoogleIcon.vue';
import emitter from '@/emitter';
import AuthLayout from '@/Layouts/AuthLayout.vue';

defineProps({
    canResetPassword: Boolean,
    status: String,
    errors: Object,
});

const form = useForm({
    email: '',
    password: '',
    remember: false,
});

const submit = () => {
    form.transform(data => ({
        ...data,
        remember: form.remember ? 'on' : '',
    })).post(route('login'), {
        onFinish: () => form.reset('password'),
        onSuccess: () =>
            emitter.$emit('loggedIn', usePage().props.auth?.user, usePage().props?.guard),
    });
};
</script>

<template>
    <Head :title="$t('Log in')" />
    <AuthLayout :title="$t('Sign in to your account')">
        <div class="mt-8">
            <div v-if="status" class="text-sm font-medium text-green-600">
                {{ status }}
            </div>
            <div class="mt-8">
                <div class="mt-1 grid">
                    <div>
                        <a
                            :href="route('oauth.redirect', 'google')"
                            class="inline-flex w-full justify-center space-x-2 rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-500 shadow-xs hover:bg-gray-50"
                        >
                            <GoogleIcon class="h-5 w-5" />
                            <span>{{ $t('Sign in with :service', { service: 'Google' }) }}</span>
                        </a>
                    </div>
                </div>

                <div class="relative mt-6">
                    <div class="absolute inset-0 flex items-center" aria-hidden="true">
                        <div class="w-full border-t border-gray-300" />
                    </div>
                    <div class="relative flex justify-center text-sm">
                        <span class="bg-white px-2 text-gray-500">
                            {{ $t('Or continue with') }}
                        </span>
                    </div>
                </div>
            </div>

            <div class="mt-6">
                <form class="space-y-6" @submit.prevent="submit">
                    <div>
                        <InputLabel for="email" :value="$t('Email address')" />
                        <TextInput
                            id="email"
                            v-model="form.email"
                            type="email"
                            class="mt-1 block w-full"
                            required
                            autofocus
                        />
                        <InputError class="mt-2" :message="form.errors.email || errors?.email" />
                    </div>
                    <div class="space-y-1">
                        <InputLabel for="password" :value="$t('Password')" />
                        <PasswordInput
                            id="password"
                            v-model="form.password"
                            class="mt-1 block w-full"
                            required
                            autocomplete="current-password"
                        />
                        <InputError class="mt-2" :message="form.errors.password" />
                    </div>
                    <div class="flex items-center justify-between">
                        <label class="flex items-center">
                            <Checkbox
                                v-model:checked="form.remember"
                                name="remember"
                                class="h-4 w-4"
                            />
                            <span for="remember-me" class="ml-2 block text-sm text-gray-900">
                                {{ $t('Remember me') }}
                            </span>
                        </label>
                        <div class="text-sm">
                            <Link
                                v-if="canResetPassword"
                                :href="route('password.request', { email: form.email || null })"
                                class="font-medium text-emerald-500 hover:text-emerald-600"
                            >
                                {{ $t('Forgot your password?') }}
                            </Link>
                        </div>
                    </div>
                    <div>
                        <FullWidthButton
                            :class="{ 'opacity-25': form.processing }"
                            :disabled="form.processing"
                        >
                            {{ $t('Sign in') }}
                        </FullWidthButton>
                    </div>
                </form>
            </div>
        </div>
    </AuthLayout>
</template>
