<script setup>
import { onMounted, ref } from 'vue';

const model = defineModel({ type: [String, Number] });

const input = ref(null);

onMounted(() => {
    if (input.value.hasAttribute('autofocus')) {
        input.value.focus();
    }
});

defineExpose({ focus: () => input.value.focus() });
</script>

<template>
    <input
        ref="input"
        v-model="model"
        class="appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-xs focus:border-emerald-400 focus:ring-3 focus:ring-emerald-500/20 focus:outline-0 sm:text-sm"
    />
</template>
